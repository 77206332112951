import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';
import BaseTrendingMenuTheme from 'base/components/TrendingMenu/theme';

const Tag = tw.theme({
  extend: TagTheme,
  defaultVariants: {
    size: 'small',
  },
});

const TrendingMenu = tw.theme({
  extend: BaseTrendingMenuTheme,
  slots: {
    base: ['w-screen', 'gap-3', 'sm:w-full'],
    headline: ['text-black', 'text-headline-2xs'],
    prefix: ['text-primary-700'],
  },
});

export default Object.assign(TrendingMenu, { Tag });
